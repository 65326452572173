import RatesRepository from "@/api/RatesRepository";
import ReservationsRepository from "@/api/ReservationsRepository";

export default {
    namespaced: true,

    state: {
        property: null,
        loading_availability: false,
        loading_create: false,
        availability: [],
        petName: null,
        date: null,
        quote: null,
        firstName: null,
        lastName: null,
        email: null,
        phone: null,
        rateId: null,
        source: null,
    },

    getters: {
        property (state) {
            return state.property;
        },
        availability (state) {
            return state.availability;
        },
        loading_availability (state) {
            return state.loading_availability;
        },
        loading_create (state) {
            return state.loading_create;
        },
        petName (state) {
            return state.petName;
        },
        date (state) {
            return state.date;
        },
        step1IsValid (state) {
            return state.petName !== null && state.petName.length > 0 && state.date !== null
        },
        step2IsValid (state) {
            return state.quote !== null && state.firstName !== null && state.lastName !== null && state.email !== null
        },
        quote (state) {
            return state.quote;
        },
        firstName (state) {
            return state.firstName;
        },
        lastName (state) {
            return state.lastName;
        },
        phone (state) {
            return state.phone;
        },
        email (state) {
            return state.email;
        },
        rateId (state) {
            return state.rateId;
        },
        source (state) {
            return state.source;
        },
    },

    mutations: {
        SET_PROPERTY (state, value) {
            state.property = value;
        },
        SET_LOADING_AVAILABILITY (state, value) {
            state.loading_availability = value;
        },
        SET_AVAILABILITY (state, value) {
            state.availability = value;
        },
        SET_PET_NAME (state, value) {
            state.petName = value;
        },
        SET_DATE (state, value) {
            state.date = value;
        },
        SET_QUOTE (state, value) {
            state.quote = value;
        },
        SET_LAST_NAME (state, value) {
            state.lastName = value;
        },
        SET_FIRST_NAME (state, value) {
            state.firstName = value;
        },
        SET_EMAIL (state, value) {
            state.email = value;
        },
        SET_PHONE (state, value) {
            state.phone = value;
        },
        SET_LOADING_CREATE (state, value) {
            state.loading_create = value;
        },
        SET_RATE_ID (state, value) {
            state.rateId = value;
        },
        SET_SOURCE (state, value) {
            state.source = value;
        },
    },

    actions: {
        setProperty ({ commit }, property) {
            commit('SET_PROPERTY', property);
            },
        reset ({ commit }) {
            commit('SET_PROPERTY', null);
            commit('SET_AVAILABILITY', []);
            commit('SET_PET_NAME', null);
            commit('SET_DATE', null);
            commit('SET_QUOTE', null);
            commit('SET_FIRST_NAME', null);
            commit('SET_LAST_NAME', null);
            commit('SET_EMAIL', null);
            commit('SET_PHONE', null);
            commit('SET_RATE_ID', null);
            commit('SET_SOURCE', null);
        },
        async loadAvailability ({ commit }, slug) {
            commit('SET_LOADING_AVAILABILITY', true);
            let response = await RatesRepository.getNewCustomerRateAvailability(slug);
            commit('SET_PROPERTY', response.property);
            commit('SET_AVAILABILITY', response.availability);
            commit('SET_RATE_ID', response.rate_id);
            commit('SET_LOADING_AVAILABILITY', false);
        },
        async getQuote ({ getters, commit }) {
            commit('SET_QUOTE', null);
            let response = await ReservationsRepository.getQuoteFromRate(getters.property.id, getters.rateId, getters.date);
            if(response >= 0) {
                commit('SET_QUOTE', response);
            }
        },
        async createReservation ({ getters, commit }) {
            commit('SET_LOADING_CREATE', true);
            try {
                let response = await ReservationsRepository.onboardingSubmit({
                    pet_name: getters.petName,
                    date: getters.date,
                    rate_id: getters.rateId,
                    first_name: getters.firstName,
                    last_name: getters.lastName,
                    email: getters.email,
                    phone: getters.phone,
                    property_slug: getters.property.urlSlug,
                    source: getters.source,
                });
                commit('SET_LOADING_CREATE', false);
                return response;
            }
            catch (error) {
                commit('SET_LOADING_CREATE', false);
                throw error
            }
        }
    }
}
