<template>
  <div>
    <v-list v-if="unvaccinatedPets.length > 0" class="my-0 py-0">
      <template v-for="(pet) in unvaccinatedPets.slice(0, 3)">
        <v-list-item :key="pet.uuid">
          <v-row >
            <v-col cols="12" sm="12">
              <v-alert
                v-if="user.unvaccinated_required_pathogens"
                dense
                icon="mdi-needle"
                text
                type="warning"
                class="mb-0"
              >
                We do not have {{ pet.name }}'s latest vaccination records.
                <a v-bind:href="`/pets`+ `/${pet.uuid}/vaccinations`">Please upload them here.</a>
              </v-alert>
            </v-col>
          </v-row>
        </v-list-item>
      </template>
    </v-list>
    <v-list v-if="packages.length > 0" class="my-0 py-0">
      <template v-for="(p) in packages">
        <v-list-item :key="p.id">
          <v-row >
            <v-col cols="12" sm="12">
              <v-alert
                v-if="isWithin30Days(p) || hasOneSession(p)"
                dense text icon="mdi-ticket" type="warning" class="mb-0">
                {{ p.name }}:
                <span v-if="hasOneSession(p) && !isWithin30Days(p)">Your package has one session left </span>
                <span v-if="!hasOneSession(p) && isWithin30Days(p)">Your package expires on date {{ moment(p.expires_at).format('DD/MM/YY') }} </span>
                <span v-if="hasOneSession(p) && isWithin30Days(p)">Your package has one session left and expires on date  {{ moment(p.expires_at).format('DD/MM/YY') }} </span>
                - click <a href="/buy-packages">here</a> to buy a new package
              </v-alert>
            </v-col>
          </v-row>
        </v-list-item>
      </template>
    </v-list>
    <v-list v-if="notCompletedTaskPets.length > 0" class="my-0 py-0">
      <template v-for="(item, index) in notCompletedTaskPets">
        <v-list-item :key="index" v-if="!item.completed_medication">
          <v-row>
            <v-col cols="12" sm="12">
              <v-alert dense text icon="mdi-account" type="warning" class="mb-0">
                <span>Please provide medication for {{item.name}}</span>
              </v-alert>
            </v-col>
          </v-row>
        </v-list-item>
        <v-list-item :key="index" v-if="!item.completed_own_food">
          <v-row>
              <v-col cols="12" sm="12">
              <v-alert dense text icon="mdi-account" type="warning" class="mb-0">
                <span>Please provide own food for {{item.name}}</span>
              </v-alert>
              </v-col>
          </v-row>
        </v-list-item>
        <v-list-item :key="index" v-if="!item.completed_tell_us_more">
          <v-row>
            <v-col cols="12" sm="12">
              <v-alert dense text icon="mdi-account" type="warning" class="mb-0">
                <span>We do not have information about {{item.name}}. Please tell us more about <router-link :to="{ name: 'ViewPet', params: { id: item.uuid } }">{{item.name}}</router-link></span>
              </v-alert>
            </v-col>
          </v-row>
        </v-list-item>
      </template>
    </v-list>
    <v-row>
      <v-col class="pb-0">
        <h1>Welcome {{ user.customer.first_name }},</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="mx-0 px-0">
        <v-list>
          <v-subheader><v-badge inline :content="total" :value="total">UPCOMING RESERVATIONS</v-badge></v-subheader>
          <v-divider/>
          <v-progress-linear
              indeterminate
              color="primary"
              v-if="loading"
          ></v-progress-linear>
          <v-list-item-group>
            <template v-for="(item, index) in items">
              <router-link tag="span" :to="generateLink(item)" :key="item.id">
              <v-list-item :key="item.id">
                <template v-slot:default>
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-chip small color="secondary" label outlined>
                        <v-icon small class="mr-1" >mdi-home</v-icon>{{ item.property.name }}
                      </v-chip>
                    </v-list-item-title>
                    <v-list-item-title class="mt-2">
                      <pet-chip :pet="item.pet"></pet-chip>
                    </v-list-item-title>
                    <v-list-item-subtitle class="text--primary mt-2"><reservation-type-chip :type="item.type" /></v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-list-item-action-text >{{ item.date | toShortDate }}</v-list-item-action-text>
                    <span>
                      <v-tooltip top v-if="item.is_recurring === true">
                            <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-bind="attrs" v-on="on">mdi-autorenew</v-icon>
                            </template>
                            <span>This is a recurring reservation</span>
                        </v-tooltip>
                        <v-btn
                            v-if="item.booking_uuid && !item.completed && ['confirmed', 'requested'].includes(item.status)"
                            color="warning" small>Complete details</v-btn>
                        <v-icon v-else>mdi-chevron-right</v-icon>
                    </span>
                    <ReservationStatusChip
                        class="mt-1"
                        :status="item.status">
                    </ReservationStatusChip>
                  </v-list-item-action>
                </template>
              </v-list-item>
              </router-link>
              <v-divider
                  v-if="index + 1 < items.length"
                  :key="'div_' + index"
              ></v-divider>
            </template>
            <v-list-item v-if="items.length === 0 && loading === false" key="no-data" class="text-center">
              <p class="ma-3 text-center">You don't have any upcoming reservations.</p>
            </v-list-item>
            <v-divider />
          </v-list-item-group>
        </v-list>
        <v-btn
            v-if="items.length !== 0"
            outlined
            class="mx-4 mt-2 float-right"
            :disabled="page === lastPage"
            @click="page++"
        >
          Next
        </v-btn>
        <v-btn
            v-if="items.length !== 0"
            outlined
            class="mx-4 mt-2 float-left float-sm-right"
            :disabled="page === 1"
            @click="page--"
        >
          Previous
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import ReservationStatusChip from "@/components/ReservationStatusChipComponent";
import PetChip from "@/components/PetChipComponent";
import ReservationTypeChip from "@/components/ReservationTypeChipComponent";
import axios from "axios";
import moment from "moment";

export default {
  name: 'Home',
  title: 'Home',
  components: {
    ReservationStatusChip,
    PetChip,
    ReservationTypeChip
  },
  mounted() {
    this.loading = true;
    this.loadReservations()
    this.loadMissingInformation()
    this.loadOnlyPackages()
    this.loading = false;
  },
  data: () => ({
    items: [],
    page: 1,
    lastPage: 1,
    loading: false,
    selected: null,
    total: 0,
    packages: [],
    notCompletedTaskPets: [],
  }),
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters({
      user: 'auth/user',
      unvaccinatedPets: 'auth/unvaccinatedPets'
    }),
    moment() {
      return moment
    },
  },
  methods: {
    generateLink(item) {
    if (item.booking_uuid && !item.completed) {
      return { name: 'BookingConfirmation', params: { id: item.id } };
    } else {
      return { name: 'ViewReservation', params: { id: item.id } };
    }
  },
    loadMissingInformation: async function() {
      await axios.get('/api/user/reservations/missing-information', {}).then((response) => {
        this.completedTaskCheckMap(response.data)
      });
    },
    loadReservations: async function(page) {
      await axios.get('/api/user/reservations/upcoming', {
        params: {
          page: page
        }
      }).then((response) => {
        this.items = response.data.data;
        this.lastPage = response.data.meta.last_page;
        this.total = response.data.meta.total;
      });
    },
    loadOnlyPackages: async function(page) {
      await axios.get('/api/user/only-packages', {
        params: {
          page: page
        }
      }).then((response) => {
        this.packages = response.data.data;
      });
    },
    completedTaskCheckMap(items) {
      items.forEach((i) => {
        if (!this.notCompletedTaskPets.find((p) => p.uuid === i.uuid)) {
          this.notCompletedTaskPets.push({
            uuid: i.uuid,
            name: i.name,
            completed_medication: i.completed_medication,
            completed_own_food: i.completed_own_food,
            completed_tell_us_more: i.completed_tell_us_more
          })
        }
      })
    },
    isWithin30Days(p) {
      return moment(p.expires_at) >= moment() && moment(p.expires_at) < moment().days(30);
    },
    hasOneSession(p) {
      return p.qty_available === 1;
    }
  },

  watch: {
    page(value) {
      this.loadReservations(value)
    },
  },
}
</script>
