<template>
  <div>
      <v-row>
        <v-col cols="12" sm="6">
          <h3> To help make your check-in as smooth as possible, we require some more details before your stay.</h3>
        </v-col>
      </v-row>
    <v-row>
      <v-col align="center" cols="12" sm="6">
        <v-card style="background-color: rgb(245, 245, 245)" flat outlined>
          <v-col class="d-flex flex-column">
            <h3 class="booking-reference-text mb-2">Booking reference:</h3>
            <span class="booking-reference-code mb-2">{{ booking.reference }}</span>
            <span class="booking-reference-text mb-2"><span>Status: </span><span style="text-transform: capitalize;" :style="{color: booking.reservations[0].reservation.status === 'confirmed' ? '#16A34A' : '#FFA500' }">{{ booking.reservations[0].reservation.status ? booking.reservations[0].reservation.status : '' }}</span></span>
          </v-col>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mb-0">
      <v-col class="pb-0" cols="12" sm="6">
        <p class="pb-0">This information needs to be completed 24 hours before your arrival.</p>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col cols="12" sm="6">
        <v-list dense>
          <v-subheader>Next steps ({{ tasksCompletedRate }}% complete):</v-subheader>
          <v-divider></v-divider>
            <v-list-item>
              <v-list-item-icon>
                <v-icon  color="green">mdi-check-circle</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Confirm Booking</v-list-item-title>
              <v-list-item-action><v-icon>mdi-chevron-right</v-icon></v-list-item-action>
            </v-list-item>
          <v-divider></v-divider>
          <div v-for="(reservation, index) in booking.reservations" :key="index">
          <v-list-item link @click="$router.push({name: 'BoardingBookingPetProfile', params: { id: reservation.id, index: index }})">
              <v-list-item-icon>
                <v-icon v-if="reservation.tasks.pet_profile" color="green">mdi-check-circle</v-icon>
                <v-icon v-else>mdi-check-circle-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Complete {{ reservation.pet.name }} details</v-list-item-title>
              <v-list-item-action><v-icon>mdi-chevron-right</v-icon></v-list-item-action>
            </v-list-item>
          <v-divider></v-divider>
          <v-list-item v-if="reservation.reservation.vaccination" link @click="$router.push({name: 'BoardingBookingVaccinationDetails', params: { id: reservation.id, index: index }})">
            <v-list-item-icon>
              <v-icon v-if="reservation.tasks.vaccination_details" color="green">mdi-check-circle</v-icon>
              <v-icon v-else>mdi-check-circle-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ reservation.pet.name }} vaccination details</v-list-item-title>
            <v-list-item-action><v-icon>mdi-chevron-right</v-icon></v-list-item-action>
          </v-list-item>
          <v-divider v-if="reservation.reservation.vaccination"></v-divider>
          <v-list-item v-if="reservation.reservation.medication" link @click="$router.push({name: 'BoardingBookingMedication', params: { id: reservation.id, index: index }})">
            <v-list-item-icon>
              <v-icon v-if="reservation.tasks.medication_details" color="green">mdi-check-circle</v-icon>
              <v-icon v-else>mdi-check-circle-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ reservation.pet.name }} medication details</v-list-item-title>
            <v-list-item-action><v-icon>mdi-chevron-right</v-icon></v-list-item-action>
          </v-list-item>
          <v-divider v-if="reservation.reservation.medication"></v-divider>
          <v-list-item v-if="reservation.reservation.own_food" link @click="$router.push({name: 'BoardingBookingOwnFood', params: { id: reservation.id, index: index }})">
            <v-list-item-icon>
              <v-icon v-if="reservation.tasks.own_food_details" color="green">mdi-check-circle</v-icon>
              <v-icon v-else>mdi-check-circle-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ reservation.pet.name }} own food details</v-list-item-title>
            <v-list-item-action><v-icon>mdi-chevron-right</v-icon></v-list-item-action>
          </v-list-item>
          <v-divider v-if="reservation.reservation.own_food"></v-divider>
          <v-list-item link  @click="$router.push({name: 'BoardingBookingAboutPet', params: { id: reservation.id, index: index }})">
            <v-list-item-icon>
              <v-icon v-if="reservation.tasks.pet_description" color="green">mdi-check-circle</v-icon>
              <v-icon v-else>mdi-check-circle-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Tell us more about {{ reservation.pet.name }}</v-list-item-title>
            <v-list-item-action><v-icon>mdi-chevron-right</v-icon></v-list-item-action>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item v-if="index === booking.reservations.length - 1" link  @click="customer_profile ? '' : $router.push({name: 'BoardingCustomerProfile', params: { id: reservation.id }})">
              <v-list-item-icon>
                <v-icon v-if="customer_profile" color="green">mdi-check-circle</v-icon>
                <v-icon v-else>mdi-check-circle-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Complete your profile</v-list-item-title>
              <v-list-item-action><v-icon>mdi-chevron-right</v-icon></v-list-item-action>
            </v-list-item>
          <v-divider v-if="index === booking.reservations.length -1"></v-divider>
          </div>
        </v-list>
      </v-col>
    </v-row>
    <!-- <img v-if="success === 'true' && reservation.property.facebook_tracking_pixel !== null" :src="`https://www.facebook.com/tr?id=${reservation.property.facebook_tracking_pixel}&ev=Lead`" height="1" width="1" style="display:none"/> -->
  </div>
</template>

<script>
import store from "@/store";

import { mapGetters } from "vuex";
export default {
  name: 'BookTrialDayConfirmation',
  title: 'Book a trial day | Confirmation',
  components: {
  },
  props: ['success'],
  async beforeRouteUpdate (to, from, next) {
    await store.dispatch("bookingConfirmation/getBooking",to.params.id);
    next();
  },
  async beforeRouteEnter (to, from, next) {
    await store.dispatch("bookingConfirmation/getBooking",to.params.id);
    next();
  },
  mounted() {
    this.booking.reservations.forEach((r) => {
      if (!(r.tasks.customer_profile && r.tasks.medication_details && r.tasks.own_food_details && r.tasks.pet_description && r.tasks.pet_profile && r.tasks.vaccination_details)) {
        this.successValue = false
      }
    })

    if (this.successValue) {
      this.$router.push({
        name: 'ViewReservation',
        params: { id: this.$route.params.id }
      });
    }
  },
  data: () => ({
    successValue: true
  }),
  computed: {
    ...mapGetters({
      booking: 'bookingConfirmation/reservation',
      customer_profile: 'bookingConfirmation/customer_profile',
      tasksCompletedRate: 'bookingConfirmation/tasksCompletedRate',
    }),
  },
}
</script>
<style>
.booking-reference-text {
    font-family: sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    color: #0B4141;
}
.booking-reference-code {
    font-family: sans-serif;
    font-size: 20px !important;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    color: #0B4141;
}
</style>