<template>
  <v-chip
      color="light"
      :small="small"
      label
  >
    <v-icon left :color="colour" :small="small">
      {{ icon }}
    </v-icon>
    {{ type | toUCFirst }}
  </v-chip>
</template>

<script>

export default {
  props: {
    type: String,
    small: {
      type: Boolean,
      default: true
    },
  },
  computed: {
    colour () {
      return (this.type === 'daycare' ? 'primary' : (this.type === 'grooming' ?  'secondary' : (this.type === 'wash' ?  'blu' : 'light' ) ))
    },
    icon () {
      return (this.type === 'daycare' ? 'mdi-weather-sunny' : (this.type === 'grooming' ?  'mdi-content-cut' : (this.type === 'wash' ?  'mdi-water' : (this.type === 'boarding' ?  'mdi-bed' : this.type === 'training' ? 'mdi-chair-school' : '' ) )))
    }
  },

}
</script>
