<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(onSubmit)">
      <v-row>
        <v-col cols="12" sm="6">
          <h1>Complete {{ pet.name }}'s profile</h1>
        </v-col>
      </v-row>
      <v-row class="mt-0 mb-2" v-if="pet.avatar_location !== null">
        <v-col cols="12" sm="6" align="center">
          <v-avatar size="120"><v-img :src="pet.avatar_location">
            <template v-slot:placeholder>
              <v-row
                  class="fill-height ma-0 primary lighten--2"
                  align="center"
                  justify="center"
              >
                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
              </v-row>
            </template>
          </v-img></v-avatar>
        </v-col>
      </v-row>
      <v-row class="mb-0">
          <v-col class="py-0" cols="12" sm="6">
            <text-input
                v-model="pet.name"
                rules="required"
                label="Name*"
                name="Name"
            ></text-input>
          </v-col>
          <v-col class="py-0" cols="12" sm="6">
            <text-input
                v-model="pet.vet"
                rules="required"
                label="Vet clinic*"
                name="Vet clinic"
            ></text-input>
          </v-col>
          <v-col class="py-0" cols="12" sm="6">
            <select-input
                v-model="pet.breed"
                :items="breeds"
                rules="required"
                label="Breed*"
                :loading="loadingBreeds"
                outlined
            />
          </v-col>
          <v-col class="py-0" cols="6" sm="3">
            <select-input
                v-model="pet.sex"
                :items="sex"
                rules="required"
                label="Sex*"
                outlined
            />
          </v-col>
        <v-col class="py-0" cols="6" sm="3">
          <select-input
              v-model="pet.desexed"
              :items="desexed"
              rules="required"
              label="Desexed*"
              outlined
          />
        </v-col>
      </v-row>
      <v-row class="my-0">
        <v-col v-if="hasBirthday === true || pet.dob !== null"  class="py-0" cols="12" sm="6">
          <date-picker
              v-model="pet.dob"
              outlined
              label="Date of Birth*"
              name="Date of Birth"
              :max="today"
              hide-details
              rules="required"
          >
          </date-picker>
          <span v-if="pet.dob === null" @click="hasBirthday = false" class="caption text-decoration-underline">Don't know {{ pet.name }}'s date of birth. Click here to enter age ></span>
        </v-col>
        <v-col v-if="hasBirthday === false"  class="py-0" cols="6" sm="3">
          <text-input
              v-model="years"
              outlined
              label="Age (years)*"
              type="number"
              name="Age (years)"
              rules="required|min_value:0"
          >
          </text-input>
        </v-col>
        <v-col v-if="hasBirthday === false"  class="py-0" cols="6" sm="3">
          <text-input
              v-model="months"
              outlined
              label="Age (months)*"
              type="number"
              name="Age (months)"
              rules="required|max_value:11|min_value:0"
          >
          </text-input>
        </v-col>
      </v-row>
      <v-row v-if="pet.avatar_location === null">
        <v-col cols="12" sm="6">
          <dropzone-upload
              ref="dropzone"
              :useCustomSlot="true"
              :files.sync="files"
              :loading.sync="uploading"
              :options='{
                                        maxFilesize: 10,
                                        maxFiles: 1,
                                        uploadMultiple: false,
                                        acceptedFiles: "image/jpeg,image/png",
                                    }'
          ><v-icon class="mt-2">mdi-upload</v-icon><p>Upload a profile picture (optional)</p><v-btn color="primary">Upload</v-btn>
          </dropzone-upload>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-btn color="success" block
                 type="submit"
                 :loading="loading"
                 :disabled="uploading"
          >
            Save
          </v-btn>
          <v-btn block
                 class="mt-3"
          @click="$router.push({name: 'BookingConfirmation', params: {id: res.id}})"
          >
            Back
          </v-btn>
        </v-col>
      </v-row>
    </form>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import { mapGetters} from "vuex";
import SelectInput from "@/components/SelectInputComponent";
import TextInput from "@/components/TextInputComponent";
import DatePicker from "@/components/DatePickerComponent";
import PetsRepository from "@/api/PetsRepository";
import store from "@/store";
import DropzoneUpload from "@/components/DropzoneUpload";
var moment = require('moment');

export default {
  name: 'BookTrialDayDetails',
  title: 'Book a trial day | Your details',
  components: {
    DropzoneUpload,
    ValidationObserver,
    SelectInput,
    TextInput,
    DatePicker,
  },
  async beforeRouteEnter(to, from, next) {
    //Check reservation confirmation is set
    if(store.getters["bookingConfirmation/reservation"] !== null) {
      next();
    }
    else {
      next('/')
    }
  },
  async beforeRouteUpdate(to, from, next) {
    //Check reservation confirmation is set
    if(store.getters["bookingConfirmation/reservation"] !== null) {
      next();
    }
    else {
      next('/')
    }
  },
  async mounted() {
    this.loadingBreeds = true;
    this.breeds = await PetsRepository.getBreeds(this.pet.petType);
    this.loadingBreeds = false;
  },
  data: () => ({
    loading: false,
    loadingBreeds: false,
    breeds: [],
    hasBirthday: true,
    years: 0,
    months: 0,
    files: [],
    uploading: false,
    sex: [
      {text: 'Male', value: 'M'},
      {text: 'Female', value: 'F'},
    ],
    desexed: [
      {text: 'Yes', value: 1},
      {text: 'No', value: 0},
    ],
  }),
  computed: {
    ...mapGetters({
      reservation: 'bookingConfirmation/reservation',
    }),
    pet() {
      return this.reservation.reservations[this.$route.params.index].pet
    },
    today () {
      return moment().format('Y-MM-DD')
    },
    res() {
      return this.reservation.reservations[this.$route.params.index].reservation
    },
  },
  methods: {
    async onSubmit () {
      this.loading = true;
      let pet = Object.assign({}, this.pet);
      if(this.hasBirthday === false) {
        pet.dob = moment().subtract(this.years, 'years').subtract(this.months, 'months').format('Y-M-D')
      }
      let response = await PetsRepository.update(this.pet.uuid, pet)
      if(response.uuid !== undefined) {
        if(this.files.length > 0) {
          await PetsRepository.processUploadedProfilePhoto(this.pet.uuid, this.files[0])
        }
        this.loading = false;
        this.$router.push({ name: 'BookingConfirmation', params: {id: this.res.id } });
      }
      this.loading = false;
    }
  },
}
</script>