<template>
  <v-card
      :class="{'rounded-0': $vuetify.breakpoint.xs}"
      outlined
  >
    <v-container>
      <v-row class="my-0">
        <v-col class="py-0">
          <v-avatar
              color="secondary"
              class="white--text"
          >{{  post.property.name | toInitials }}</v-avatar>
          <span class="subtitle-1 font-weight-medium ml-2">{{ post.property.name }}</span> <span class="caption">{{ post.published_at | toHumanReadableDate}}</span>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="pb-1">
          <text-view :text="post.message" :textLength="250" />
        </v-col>
      </v-row>
    </v-container>
    <v-responsive
        :aspect-ratio="$vuetify.breakpoint.xs ? null : 16/9" v-if="post.media.length > 0">
      <v-carousel height="auto" hide-delimiters :show-arrows="post.media.length > 1">
        <v-carousel-item
            v-for="(media,i) in post.media" :key="i"
        >
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  icon
                  class="v-btn--absolute theme--dark"
                  style="z-index: 100; right: 0"
                  v-bind="attrs"
                  v-on="on"
              >
                <v-icon>mdi-share</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                  key="download"
                  @click="media.type === 'mux-video' ? downloadVideo(media) : downloadImage(media)"
              >
                <gb-social-button
                    :network="`opera`"
                    :size="socialMediaIconSize"
                    :theme="socialMediaIconTheme"
                    :iconPath="`${baseUrl}/networks`"
                >
                </gb-social-button>
              </v-list-item>
              <v-list-item
                  v-for="(social, index) in selectedSocialMedias(media, $vuetify.breakpoint.name)"
                  :key="index"
              >
                <ShareNetwork
                    :network="social"
                    :url="getUrl(media)"
                    :title="post.message ? post.message : ''"
                    :quote="post.message ? post.message : ''"
                >
                  <gb-social-button
                      :network="social"
                      :size="socialMediaIconSize"
                      :theme="socialMediaIconTheme"
                      :iconPath="`${baseUrl}/networks`"
                  >
                  </gb-social-button>
                </ShareNetwork>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-sheet color="black">
          <v-img
              :aspect-ratio="$vuetify.breakpoint.xs ? null : 16/9"
              v-if="media.type === 'image'"
              :src="media.url"
              contain
          >
            <template v-slot:placeholder>
              <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
              >
                <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
          <video-player
              v-else-if="media.type === 'mux-video'"
              :options="getVideoOptions(media)"
          />
        </v-sheet>
        </v-carousel-item>
      </v-carousel>
    </v-responsive>
  </v-card>
</template>

<script>

import VideoPlayer from "@/components/VideoPlayer";
import {mapGetters} from "vuex";
import TextView from "./ui/TextView.vue";
import axios from "axios";

export default {
  props: {
    post: Object,
  },
  components: {
    VideoPlayer,
    TextView,
  },
  data: () => ({
    baseUrl: window.location.origin,
    socialMediaIconSize: 'small',
    socialMediaIconTheme: 'light',
    socialMedias: ['facebook', 'messenger', 'whatsapp', 'linkedin']
  }),
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters({
      customer: 'auth/customer'
    })
  },
  methods: {
    selectedSocialMedias(media, breakpoint) {
      return this.socialMedias.filter((s) => {
        return !(media.type === 'mux-video' && s === 'linkedin')
      }).filter((s) => {
        return !((breakpoint !== 'xs' && breakpoint !== 'sm'  && breakpoint !== 'md') && s === 'messenger')
      })
    },
    getVideoOptions: function (media) {
      return {
        autoplay: false,
        controls: true,
        fluid: true,
        preload: 'metadata',
        poster: "https://image.mux.com/" + media.mux_video_playback_id + "/thumbnail.png?width=860",
        sources: [
          {
            src: "https://stream.mux.com/" + media.mux_video_playback_id + ".m3u8",
            type: "application/x-mpegURL"
          },
          {
            src: "https://stream.mux.com/" + media.mux_video_playback_id + "/medium.mp4",
            type: "video/mp4"
          }
        ],
        plugins: {
          mux: {
            debug: true,
            data: {
              env_key: process.env.VUE_APP_MUX_DATA_PUBLIC_KEY,
              // Metadata
              player_name: "Customer portal feed",
              video_id: media.mux_video_playback_id,
              viewer_user_id: this.customer.uuid,
              sub_property_id: this.post.property.name,
            }
          }
        }
      }
    },
    getUrl(media) {
      if(media.type === 'image') {
        return media.url
      } else if(media.type === 'mux-video') {
        return this.getVideoOptions(media).sources[1].src
      }
      return null;
    },
    downloadImage(media) {
      axios.get(media.url, {
        responseType: 'blob',
      }).then((response) => {
        const url = URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        const extension = media.url.split('.').pop()
        link.setAttribute('download', media.type + '.' + extension);
        document.body.appendChild(link);
        link.click();
      })
    },
    downloadVideo(media) {
      fetch(media.url, {
        method: 'GET',
      })
          .then((response) => {
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.blob();
          })
          .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            const extension = media.url.split('.').pop()
            a.setAttribute('download', media.type + '.' + extension);
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);
          })
          .catch((error) => {
            console.error('Error downloading:', error);
          });
    },
  }
}
</script>
<style>
.video-js .vjs-tech {
  width: 100%;
  height: 100%;
  object-fit: fill; /* Ensures the video covers the container */
}

.video-js .vjs-poster {
  object-fit: cover; /* Ensures the poster covers the container */
  background-size: cover;
  background-position: center;
}

.video-js .vjs-poster img {
  object-fit: fill;
}
</style>

