import CustomersRepository from "@/api/CustomersRepository";

export default {
    namespaced: true,

    state: {
        customer: {},
    },

    getters: {
        pet(state) {
            return state.customer
        },
    },

    mutations: {
        SET_CUSTOMER(state, value) {
            state.customer = value
        },
    },

    actions: {
        async get({commit}) {
            let customer = await CustomersRepository.get()
            await commit('SET_CUSTOMER', customer.data);
        },
        // eslint-disable-next-line no-empty-pattern
        async startVetConsult ({}, payload) {
            return  await CustomersRepository.startVetConsult(payload);
        },
    }
}