<template>
    <div>
      <v-row>
        <v-col cols="12" sm="6">
          <h1>Purchase daycare packages</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" class="py-0">
          <span class="subheading">Location</span>
          <v-select
              v-model="selectedProperty"
              :items="properties"
              item-value="id"
              item-text="name"
              placeholder="Location"
              outlined
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" class="py-0">
          <span class="subheading">Package Type</span>
          <v-select 
            v-model="selectedPackage"
            :items="packageTypes"
            :loading="packagesLoading"
            item-value="id"
            item-text="name"
            noDataText="No packages are available at this location"
            outlined
          ></v-select>
        </v-col>
      </v-row>
      <v-row v-if="selectedPackages !== null">
          <v-col v-if="!hasCoupon" cols="12" class="pt-0" @click="showCoupon = !showCoupon">
            <span style="color: #4582EC; cursor: pointer;">{{showCoupon ? `I don't have a coupon code`:'Apply a coupon code'}}</span>
          </v-col>
          <v-col v-if="!hasCoupon && showCoupon" cols="12" sm="6" class="py-0">
              <ValidationObserver v-slot="{ handleSubmit }">
                  <form @submit.prevent="handleSubmit(applyCouponCode)" autocomplete="off">
                      <v-row>
                          <v-col cols="8">
                              <span class="subheading">Coupon code</span>
                              <text-input outlined dense hide-details v-model="code" name="Coupon code"></text-input>
                          </v-col>
                          <v-col cols="4" class="mt-6">
                              <v-btn :loading="loading" type="submit" color="success" block :disabled="!code">Apply</v-btn>
                          </v-col>
                      </v-row>
                  </form>
              </ValidationObserver>
          </v-col>
          <v-col v-else-if="hasCoupon && showCoupon" cols="12" sm="6" class="py-0 mb-2">
              <b>Coupon {{ couponCode.code }} applied</b> <v-btn @click="removeCoupon" class="float-right" color="error" small>Remove</v-btn>
          </v-col>
      </v-row>
      <v-row v-if="selectedPackages !== null">
        <v-col cols="12" sm="6" class="pt-0 pb-5">
          <v-card
              outlined
              color="grey lighten-4"
          >
            <v-card-title>Purchase details</v-card-title>
            <v-card-text>
                <p><b>Cost:</b> {{ discountedPrice !== '' &&  discountedPrice !== undefined && discountedPrice !== null && discountedPrice >= 0 ? discountedPrice : selectedPackages.price | toCurrency }}</p>
                <p><b>Included redemptions: </b> {{ selectedPackages.qty_issued }}</p>
                <p><b>Expiry months: </b> {{ selectedPackages.expiry_months }}</p>
            </v-card-text>

  
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="discountedPrice !== 0">
        <v-col cols="12" sm="6" class="py-0">
          <span class="subheading mb-3">Credit card details</span>
          <stripe-element-card
              ref="stripeForm"
              :pk="stripePK"
              @token="handleToken"
              @loading="setLoading"
              :disableAdvancedFraudDetection="true"
          />
          <v-alert
              type="success"
              dense
              text
              icon="mdi-lock"
          >Your credit card details are securely encrypted and stored
          </v-alert>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-btn color="success" block
                 :disabled="!canSubmit"
                 @click="handleClick"
                 :loading="isLoading"
          >
            Purchase
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </template>
  
  <script>
  import store from '../store'
  import PackagesRepository from '../api/PackagesRepository.js';
  import {mapActions, mapGetters} from "vuex";
  import { StripeElementCard } from '@vue-stripe/vue-stripe';
  import { ValidationObserver } from 'vee-validate'
  import TextInput from "../components/TextInputComponent.vue";
  
  export default {
    title: 'Buy Packages',
    name: 'BuyPackages',
    components: {
        StripeElementCard,
        ValidationObserver,
        TextInput,
    },
    props: ['id'],
    async beforeRouteUpdate (to, from, next) {
      if(to.params.id != undefined)
      {
        await store.dispatch("customer/get",to.params.id)
      }
      next();
    },
  
    async beforeRouteEnter (to, from, next) {
      if(to.params.id != undefined)
      {
        await store.dispatch("customer/get",to.params.id)
      }
      next();
    },
    mounted() {
        this.selectedProperty = this.properties[0].id;
    },
    beforeUnmount() {
      this.$refs.stripeForm.destroy();
    },
    computed: {
      ...mapGetters({
        properties: 'auth/properties',
        customer: 'auth/customer',
        packageTypes: 'property/packageTypes'
      }),
      canSubmit ()  {
        return this.selectedPackage !== null;
      },
      selectedPackages()  {
        return this.selectedPackage !== null ? this.packageTypes.find(element => element.id === this.selectedPackage) : null;
      },
      isLoading ()  {
        return (this.loading || this.tokenLoading);
      },
      hasCoupon() {
            return this.couponCode !== '';
        },
        discounPricePositive() {
            return this.selectedPackages.price - this.discountedPrice
        }
    },
    data () {
      return {
        loading: false,
        tokenLoading: false,
        type: null,
        quantity: null,
        packagesLoading: false,
        selectedProperty: null,
        selectedPackage: null,
        discountedPrice: null,
        couponCode: '',
        code: '',
        showCoupon: false,
        stripePK: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
      }
    },
    methods: {
      ...mapActions({
        syncPackages: 'property/syncPackages',
      }),
      async handleClick () {
        if(this.$refs.stripeForm){
            return this.$refs.stripeForm.submit();
        }
        this.loading = true;
        let payload = {
          coupon_code: this.code,
        };
        let success = await PackagesRepository.purchasePackage(this.selectedProperty, this.selectedPackage, payload)
        this.loading = false;
        if (success) {
          this.selectedPackage = null;
          this.$router.push({name: 'ViewPackages'})
        }
      },
      async handleToken (token) {
        this.loading = true;
        let payload = {
          coupon_code: this.showCoupon ? this.code : '',
          stripe_token: token.id
        };
        let success = await PackagesRepository.purchasePackage(this.selectedProperty, this.selectedPackage, payload)
        this.loading = false;
        if(success) {
          this.selectedPackage = null;
          this.$refs.stripeForm.clear();
          this.$router.push({name: 'ViewPackages'})
        }
      },
      setLoading (loading) {
        this.tokenLoading = loading;
      },
      async applyCouponCode() {
            this.loading = true;
            let response = await PackagesRepository.addCoupon(this.selectedProperty, this.selectedPackages.id, this.code);
            if(response) {
                this.couponCode = response.coupon
                this.discountedPrice = response.discounted_price
            }
            this.loading = false;
        },
        removeCoupon() {
            this.loading = true
            this.code = null
            this.couponCode = ''
            this.discountedPrice = null
            this.loading = false
        },
    },
    watch: {
      selectedProperty(id) {
        this.selectedPackage = null;
        this.syncPackages(id);
      },
      selectedPackage(newValue, oldValue) {
      if (newValue !== oldValue && this.couponCode) {
        this.applyCouponCode();
      }
    }
    }
  }
  </script>
  