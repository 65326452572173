<template>
    <ValidationProvider tag="div" :rules="rules" :name="name" :vid="vid" ref="provider"  v-slot="{ errors }">
        <v-text-field
            v-bind="$attrs"
            class="phone-number-component"
            :disabled="disabled"
            v-model="currentValue"
            :name="name"
            :label="label"
            :outlined="outlined"
            :error-messages="customErrors ? customErrors : errors"
            type="tel"
            :id="id"
            defaultCountry="AU"
            @input="handleValidation"
            @blur="blurValidation"
            validCharactersOnly
            autocomplete="off"
        />
    </ValidationProvider>    
</template>

<script>
    import { ValidationProvider } from 'vee-validate';
    import 'intl-tel-input/build/css/intlTelInput.css';
    import intlTelInput from "intl-tel-input/build/js/intlTelInputWithUtils"

    export default {
        components: {
            ValidationProvider,
        },
        props: {
            value: {
                type: String,
            },
            rules: {
                type: [String, Object],
                default: ''
            },
            name: {
                type: String,
                default: ''
            },
            vid: {
                type: String,
                default: undefined
            },
            max: {
                type: Number,
                default: null
            },
            label: {
                type: String,
                default: 'Phone'
            },
            outlined: {
                type: Boolean,
                default: false,
            },
            loading: {
                type: Boolean,
                default: false,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            id: {
                type: String,
                required: true,
            }
        },
        mounted() {
            const input = document.querySelector("#" + `${this.id}`);
            this.phoneNumberInput = intlTelInput(input, {
                initialCountry: "au",
                nationalMode: false,
                useFullscreenPopup: false,
                utilsScript: "../node_modules/intl-tel-input/build/js/utils.js",
            });
            input.addEventListener("countrychange", this.handleCountryChange);
            this.$nextTick(() => {
                if (this.currentValue) {
                    this.phoneNumberInput.setNumber(this.currentValue);
                }
            });
        },
        data: () => ({
            currentValue: '',
            edit:true,
            selectedCountry: '',
            error: false,
            customErrors: '',
            phoneNumberInput: null,
        }),
        methods: {
            handleValidation (number) {
                this.currentValue = this.phoneNumberInput.getNumber();

                if (this.phoneNumberInput.isValidNumberPrecise() === false && !number && this.rules === '') {
                    this.error = false
                } else if(this.phoneNumberInput.isValidNumberPrecise() === false) {
                    this.error = true
                }
                else {
                    this.error = false
                    this.customErrors = ''
                }
            },
            handleCountryChange() {
                this.currentValue = this.phoneNumberInput.getNumber();
                this.handleValidation()
            },
            blurValidation() {
                if(!this.phoneNumberInput.isValidNumberPrecise()) {
                    this.customErrors = 'Phone is not valid.'
                } else {
                    this.customErrors = ''
                }
            },
        },
        watch: {
            currentValue (val) {
                // allows us to use v-model on our input.
                if(val) {
                    this.phoneNumberInput.setNumber(val);
                }
                this.$emit('input', val);
                this.$emit('errorValue', this.error)
            },
            value (val) {
                // allows us to use v-model on our input.
                this.currentValue = val;
            },
        },

    };
</script>

<style>
/* .iti {
  --iti-path-flags-1x: url('../node_modules/intl-tel-input/build/img/flags.webp');
  --iti-path-flags-2x: url('../node_modules/intl-tel-input/build/img/flags@2x.webp');
  --iti-path-globe-1x: url('../node_modules/intl-tel-input/build/img/globe.webp');
  --iti-path-globe-2x: url('../node_modules/intl-tel-input/build/img/globe@2x.webp');
} */
 .phone-number-component .v-label {
    margin-left: 48px !important;
 }
 .phone-number-component .v-label.v-label--active {
    margin-left: 0px !important;
 }
 .phone-number-component .v-label.v-label--active {
    margin-left: 0px;
 }
 .iti__search-input {
    display: none;
 }
 .iti__tel-input {
    color: rgba(0, 0, 0, .87) !important;
 }
</style>