import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store';
import UpdatesFeed from "@/views/UpdatesFeed";
import ChatToVet from "@/views/ChatToVet.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      auth: true
    },
  },
  {
    path: '/feed',
    name: 'UpdatesFeed',
    component: UpdatesFeed,
    meta: {
      auth: true
    },
  },
  {
    path: '/speak',
    name: 'ChatToVet',
    component: ChatToVet,
    meta: {
      auth: true
    },
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue'),
  },
  {
    path: '/register',
    name: 'Register',
    props: (route) => ({ initialEmail: route.query.email ?? null, token: route.query.token ?? null }),
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Register.vue'),
  },
  {
    path: '/verify',
    name: 'Verify',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Verify.vue'),
    meta: {
      auth: true,
      hideFab: true,
    },
  },
  {
    path: '/email/verify/:id/:hash',
    name: 'VerifyEmail',
    meta: {
      noRedirect: true,
      hideFab: true,
    },
    beforeEnter: async (to, from, next) => {
      const payload = {
        id: to.params.id,
        hash: to.params.hash,
        expires: to.query.expires,
        signature: to.query.signature,
      };
      try {
        await store.dispatch('auth/verifyEmail', payload)
        store.dispatch('toast/trigger', {text: 'Your email has been verified', color: "green"})
        next('/')
      }
      catch {
        store.dispatch('toast/trigger', {text: 'An error has occurred and your email has not been verified.', color: "red"})
        next('/verify')
      }
    }
  },
  {
    path: '/password/reset',
    name: 'PasswordReset',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/PasswordReset.vue'),
  },
  {
    path: '/password/reset/:token',
    name: 'PasswordSet',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/PasswordSet.vue'),
    props: (route) => ({ token: route.params.token, email: route.query.email ?? null })
  },
  {
    path: '/reservations/create',
    name: 'CreateReservation',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/CreateReservation.vue'),
    meta: {
      auth: true
    },
  },
  {
    path: '/reservations/:id',
    name: 'ViewReservation',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ViewReservation.vue'),
    meta: {
      auth: true
    },
  },
  {
    path: '/invoices',
    name: 'ViewInvoices',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ViewInvoices.vue'),
    meta: {
      auth: true
    },
  },
  {
    path: '/pay-invoice/:id',
    name: 'PayInvoice',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/PayInvoice.vue'),
    meta: {
      auth: true
    },
  },
  {
    path: '/profile',
    name: 'Profile',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Profile.vue'),
    meta: {
      auth: true
    },
  },
  {
    path: '/pets',
    name: 'ViewPets',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ViewPets.vue'),
    meta: {
      auth: true
    },
  },
  {
    path: '/centres',
    name: 'ViewCentres',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ViewCentres.vue'),
    meta: {
      auth: true
    },
  },
  {
    path: '/centres/add',
    name: 'AddCentre',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AddCentre.vue'),
    meta: {
      auth: true
    },
  },
  {
    path: '/pets/:id',
    name: 'ViewPet',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Pet.vue'),
    meta: {
      auth: true
    },
  },
  {
    path: '/pets/:id/vaccinations',
    name: 'ViewPetVaccination',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AddVaccinationDetails.vue'),
    meta: {
      auth: true
    },
  },
  {
    path: '/pets/:id/medications',
    name: 'MedicationList',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/MedicationList.vue'),
    meta: {
      auth: true
    },
  },
  {
    path: '/pets/:id/medications/add-medication',
    name: 'AddMedication',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AddMedication.vue'),
    meta: {
      auth: true
    },
  },
  {
    path: '/pets/:id/own-food',
    name: 'OwnFoodList',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/OwnFoodList.vue'),
    meta: {
      auth: true
    },
  },
  {
    path: '/pets/:id/own-food/add-own-food',
    name: 'AddOwnFood',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AddOwnFood.vue'),
    meta: {
      auth: true
    },
  },
  {
    path: '/packages',
    name: 'ViewPackages',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ViewPackages.vue'),
    meta: {
      auth: true
    },
  },
  {
    path: '/buy-packages',
    name: 'BuyPackages',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/BuyPackages.vue'),
    meta: {
      auth: true
    },
  },
  {
    path: '/boardingBooking/:id',
    name: 'BookingConfirmation',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/boarding/Confirmation.vue'),
    meta: {
      auth: true
    },
  },
  {
    path: '/boardingBooking/actions/pet-profile',
    name: 'BoardingBookingPetProfile',
    component: () => import(/* webpackChunkName: "about" */ '../views/boarding/CompletePetProfile.vue'),
    meta: {
      auth: true,
      hideFab: true,
    },
  },
  {
    path: '/boardingBooking/actions/vaccinations',
    name: 'BoardingBookingVaccinationDetails',
    component: () => import(/* webpackChunkName: "about" */ '../views/boarding/VaccinationDetails.vue'),
    meta: {
      auth: true,
      hideFab: true,
    },
  },
  {
    path: '/boardingBooking/actions/medications',
    name: 'BoardingBookingMedication',
    component: () => import(/* webpackChunkName: "about" */ '../views/boarding/Medication.vue'),
    meta: {
      auth: true,
      hideFab: true,
    },
  },
  {
    path: '/boardingBooking/actions/own-food',
    name: 'BoardingBookingOwnFood',
    component: () => import(/* webpackChunkName: "about" */ '../views/boarding/OwnFood.vue'),
    meta: {
      auth: true,
      hideFab: true,
    },
  },
  {
    path: '/boardingBooking/actions/about-pet',
    name: 'BoardingBookingAboutPet',
    component: () => import(/* webpackChunkName: "about" */ '../views/boarding/AboutPet.vue'),
    meta: {
      auth: true,
      hideFab: true,
    },
  },
  {
    path: '/boardingBooking/actions/customer-profile',
    name: 'BoardingCustomerProfile',
    component: () => import(/* webpackChunkName: "about" */ '../views/boarding/CompleteProfile.vue'),
    meta: {
      auth: true,
      hideFab: true,
    },
  },
  {
    path: '/membership/create',
    name: 'CreateMembership',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/CreateMembership.vue'),
    meta: {
      auth: true
    },
  },
  {
    path: '/onboarding/:propertySlug',
    name: 'OnboardingStep1',
    props: (route) => ({ source: route.query.source ?? null }),
    component: () => import(/* webpackChunkName: "about" */ '../views/onboarding/Step1Date.vue'),
  },
  {
    path: '/onboarding/:propertySlug/details',
    name: 'OnboardingStep2',
    props: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/onboarding/Step2Details.vue'),
  },
  {
    path: '/onboarding/:id/confirmation',
    name: 'OnboardingConfirmation',
    props: (route) => ({ success: route.query.success ?? null }),
    component: () => import(/* webpackChunkName: "about" */ '../views/onboarding/confirmation.vue'),
    meta: {
      auth: true,
      hideFab: true,
    },
  },
  {
    path: '/onboarding/actions/about-pet',
    name: 'OnboardingAboutPet',
    component: () => import(/* webpackChunkName: "about" */ '../views/onboarding/AboutPet.vue'),
    meta: {
      auth: true,
      hideFab: true,
    },
  },
  {
    path: '/onboarding/actions/pet-profile',
    name: 'OnboardingPetProfile',
    component: () => import(/* webpackChunkName: "about" */ '../views/onboarding/CompletePetProfile.vue'),
    meta: {
      auth: true,
      hideFab: true,
    },
  },
  {
    path: '/onboarding/actions/customer-profile',
    name: 'OnboardingCustomerProfile',
    component: () => import(/* webpackChunkName: "about" */ '../views/onboarding/CompleteProfile.vue'),
    meta: {
      auth: true,
      hideFab: true,
    },
  },
  {
    path: '/onboarding/actions/vaccinations',
    name: 'OnboardingVaccinationDetails',
    component: () => import(/* webpackChunkName: "about" */ '../views/onboarding/VaccinationDetails.vue'),
    meta: {
      auth: true,
      hideFab: true,
    },
  },
  {
    path: '*',
    name: '404',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/404.vue')
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach(async (to, from, next) => {
  store.dispatch("auth/startLoading");
  const loggedIn = await store.getters['auth/authenticated'];

  async function checkEmailIsVerified(authenticatedUrl = undefined)
  {
    if(await store.getters['auth/hasVerifiedEmail'] || to.name === 'VerifyEmail') {
      return next(authenticatedUrl);
    }
    else {
      if(to.name === 'Verify') {
        return next();
      }
      else  {
        store.dispatch("auth/stopLoading");
        return next('/verify');
      }
    }
  }

  async function checkUserIsAuthenticated(authenticatedUrl = undefined, unauthenticatedUrl = undefined)
  {
    if(loggedIn) {
      await checkEmailIsVerified(authenticatedUrl)
    }
    else {
      try {
        await store.dispatch('auth/me');
        await checkEmailIsVerified(authenticatedUrl)
      }
      catch(error) {
        store.dispatch("auth/stopLoading");
        next(unauthenticatedUrl)
      }
    }
  }

  if(to.matched.some(record => record.meta.auth)) {
    //Route is protected
    let loginUrl = (to.path === '/login' || to.path === '/') ? '/login': `/login?redirect=${to.path}`;
    await checkUserIsAuthenticated(undefined, loginUrl)
  }
  else  {
    //Route is public
    //If route is marked as no redirect continue, otehrwise if logged in redirect to /
    if(!to.matched.some(record => record.meta.noRedirect)) {
      checkUserIsAuthenticated('/')
    }
    next();
  }
})

// eslint-disable-next-line no-unused-vars
router.afterEach((to, from, next) => {
  store.dispatch("auth/stopLoading");
})

export default router
