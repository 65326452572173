import PetsRepository from "@/api/PetsRepository";

export default {
    namespaced: true,

    state: {
        pet: {},
        pets: [],
        petsTotal: 0,
    },

    getters: {
        pet(state) {
            return state.pet
        },
        pets(state) {
            return state.pets
        },
        petsTotal(state) {
            return state.petsTotal
        },
    },

    mutations: {
        SET_PET(state, value) {
            state.pet = value
        },
        SET_PETS(state, value) {
            state.pets = value
        },
        SET_PETS_TOTAL(state, value) {
            state.petsTotal = value
        },
    },

    actions: {
        async get({commit}, id) {
            let reservation = await PetsRepository.get(id)
            await commit('SET_PET', reservation.data);
        },
        async index({commit}) {
            let response = await PetsRepository.index();
            await commit('SET_PETS', response.data);
            await commit('SET_PETS_TOTAL', response.total)
            return true;
        },
    }
}