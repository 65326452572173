<template>
    <div>
        <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
                <v-row>
                <v-col cols="12" md="6">
                    <h1>{{pet.name}}'s Own food details</h1>
                </v-col>
                </v-row>
                <v-row class="d-flex flex-column mt-5">
                    <v-col cols="12" md="6" class="py-0">
                        <select-input rules="required" placeholder="Type of food" name="Type" label="Food type*" v-model="type" :items="typeItems"/>
                    </v-col>
                    <v-col cols="12" md="6" class="py-0">
                        <text-input rules="required" placeholder="Brand & specifications" name="Brand & specifications" label="Brand & specifications*" v-model="brand"/>
                    </v-col>
                    <v-col cols="12" md="6" class="py-0">
                        <text-input rules="required" placeholder="Measurement per meal" name="Measurement per meal" label="Measurement per meal*" v-model="measurement"/>
                    </v-col>
                    <v-col cols="12" md="6" class="py-0">
                        <ValidationProvider tag="div" rules="required" vid="feeding_time" name="Feeding time*" v-slot="{ errors }">
                            <v-combobox 
                                label="Feeding time" 
                                :items="timeItems" 
                                v-model="feedingTime" 
                                :error-messages="errors"
                                multiple
                                outlined
                            />
                        </ValidationProvider>
                    </v-col>
                </v-row>
                <v-row>
                <v-col cols="12" md="6">
                    <v-btn color="success" block
                        :loading="loading"
                        type="submit"
                    >
                    Save
                    </v-btn>
                    <v-btn block
                        class="mt-3"
                    @click="$router.push({ name: 'BookingConfirmation', params: {id: res.id } })"
                    >
                    Back
                    </v-btn>
                </v-col>
                </v-row>
            </form>
        </ValidationObserver>
    </div>
  </template>
  
  <script>
  import { ValidationObserver, ValidationProvider } from "vee-validate";
  import { mapGetters} from "vuex";
  import PetsRepository from "@/api/PetsRepository";
  import TextInput from "../../components/TextInputComponent.vue";
  import SelectInput from '../../components/SelectInputComponent.vue';
  import store from "@/store";

  export default {
    title: 'Upload Own food details',
    components: {
      TextInput,
      ValidationObserver,
      SelectInput,
      ValidationProvider,
    },
    async beforeRouteEnter(to, from, next) {
      //Check reservation confirmation is set
      if(store.getters["bookingConfirmation/reservation"] !== null) {
        next();
      }
      else {
        next('/')
      }
    },
    async beforeRouteUpdate(to, from, next) {
      //Check reservation confirmation is set
      if(store.getters["bookingConfirmation/reservation"] !== null) {
        next();
      }
      else {
        next('/')
      }
    },
    data: () => ({
      typeItems: [
        'Dry', 'Wet', 'Raw', 'Home-cooked', 'Other'
      ],
      timeItems: [
        'AM', 'Midday', 'PM'
      ],
      type: null,
      feedingTime: [],
      loading: false,
      brand: '',
      measurement: '',


    }),
    computed: {
      ...mapGetters({
        reservation: 'bookingConfirmation/reservation',
        customer: 'auth/customer',
      }),
      pet() {
        return this.reservation.reservations[this.$route.params.index].pet
      },
      res() {
        return this.reservation.reservations[this.$route.params.index].reservation
      },
    },
    methods: {
      async onSubmit () {
        this.loading = true;
        let response = await PetsRepository.uploadOwnFood(this.pet.uuid, {
          food_type: this.type,
          brand_and_specifications: this.brand,
          measurement_per_meal: this.measurement,
          feeding_time: [...this.feedingTime],
        })
        this.loading = false;
        if(response) {
          this.$router.push({ name: 'BookingConfirmation', params: {id: this.res.id } });
        }
  
      }
    },
  }
  </script>