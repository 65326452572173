import ReservationsRepository from "@/api/ReservationsRepository";

export default {
    namespaced: true,

    state: {
        reservation: null,
        customer_profile: false,
    },

    getters: {
        reservation (state) {
            return state.reservation;
        },
        customer_profile (state) {
            return state.customer_profile;
        },
        tasksCompletedRate (state) {
            let customer_profile = false
            let values = [].concat(...state.reservation.reservations.map(reservation => {
                customer_profile = reservation.tasks['customer_profile']
                delete reservation.tasks['customer_profile']
                return Object.values(reservation.tasks);
              }));
            values.push(true) // Added true value in order to fill out "Confirm Booking"
            values.push(customer_profile) // Added customer_profile value

            const n = values.length;
            let completedTasks = values.filter(function(item) {
                return item;
            })
            return Math.round(completedTasks.length/n * 100);
        }
    },

    mutations: {
        SET_RESERVATION (state, value) {
            state.reservation = value;
        },
        SET_CUSTOMER_PROFILE (state, value) {
            state.customer_profile = value.reservations[0].tasks.customer_profile;
        },
    },

    actions: {
        async getBooking ({ commit }, booking_id) {
            let response = await ReservationsRepository.getOnboarding(booking_id);
            commit('SET_CUSTOMER_PROFILE', response.data);
            commit('SET_RESERVATION', response.data);
        },
    }
}
